export default class ExpertiseSwitcher {
    private container: HTMLElement;
    private expertiseEntries: NodeListOf<HTMLElement>;
    private previews: NodeListOf<HTMLElement>;
    private inlinePreviews: NodeListOf<HTMLElement>;

    constructor(container) {
        this.container = container;

        this.expertiseEntries = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".expertise-entry");
        this.previews = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".preview");
        this.inlinePreviews = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".preview-inline");

        this.expertiseEntries.forEach((entry) => {
            entry.addEventListener("click", (e) => {
                if (!entry.classList.contains("active")) {
                    const indexName = Array.from(entry.classList).find((className) => className.startsWith("index-"));
                    this.setActiveState(indexName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.expertiseEntries, className);
        this.toggleActiveStage(this.previews, className);
        this.toggleActiveStage(this.inlinePreviews, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
