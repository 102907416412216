export default class InfoSwitcher {
    private container: HTMLElement;
    private valueHeadings: NodeListOf<HTMLElement>;
    private texts: NodeListOf<HTMLElement>;
    private images: NodeListOf<HTMLImageElement>;

    constructor(container) {
        this.container = container;

        this.valueHeadings = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".value-heading");
        this.texts = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".value-text");
        this.images = <NodeListOf<HTMLImageElement>>this.container.querySelectorAll(".value-image");

        this.valueHeadings.forEach((entry) => {
            entry.addEventListener("click", (e) => {
                if (!entry.classList.contains("active")) {
                    const indexName = Array.from(entry.classList).find((className) => className.startsWith("index-"));
                    this.setActiveState(indexName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.valueHeadings, className);
        this.toggleActiveStage(this.texts, className);
        this.moveToFront(className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }

    private moveToFront(className: string) {
        this.images.forEach((image) => {
            const currentZIndex = getComputedStyle(image).getPropertyValue("z-index");
            if (image.classList.contains(className)) {
                this.images.forEach((jmage) => {
                    const prevZIndex = getComputedStyle(jmage).getPropertyValue("z-index");
                    if (prevZIndex === "3") {
                        jmage.style.zIndex = currentZIndex;
                    }
                });
                image.style.zIndex = "3";
            }
        });
    }
}
