import * as lottie from "lottie-web";

export default class ContactButton {
    private container: HTMLElement;
    private button: HTMLElement;
    private animation;
    constructor(container) {
        this.container = container;
        this.button = this.container.querySelector(".contact-button");

        // @ts-ignore because lottie is not recognized as conrrect type
        this.animation = lottie.loadAnimation({
            container: this.button,
            path: "/assets/animations/Fuchs.json",
            renderer: "svg",
            loop: true,
        });

        this.button.addEventListener("mouseover", (e) => {
            this.animation.play();
        });
        this.button.addEventListener("mouseleave", (e) => {
            this.animation.stop();
        });
    }
}
