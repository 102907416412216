import * as lottie from "lottie-web";

export default class ContactSection {
    private container: HTMLElement;
    private tags: NodeListOf<HTMLElement>;
    private step1s: NodeListOf<HTMLElement>;
    private step2s: NodeListOf<HTMLElement>;
    private step3s: NodeListOf<HTMLElement>;
    private continue: HTMLElement;
    private back: HTMLElement;
    private message: HTMLTextAreaElement;
    private animationContainer: HTMLElement;
    private contactNoForm: HTMLElement;
    private animation;

    constructor(container: HTMLElement) {
        this.container = container;

        this.step1s = this.container.querySelectorAll(".step-1");
        this.step2s = this.container.querySelectorAll(".step-2");
        this.step3s = this.container.querySelectorAll(".step-3");
        this.tags = this.container.querySelectorAll(".tag");
        this.message = this.container.querySelector(".message textarea");
        this.continue = this.container.querySelector(".continue");
        this.back = this.container.querySelector(".back");
        this.animationContainer = this.container.querySelector(".send-animation");
        this.contactNoForm = this.container.querySelector(".contact-no-form.mobile");

        // @ts-ignore because lottie is not recognized as conrrect type
        this.animation = lottie.loadAnimation({
            container: this.animationContainer,
            path: "/assets/animations/send.json",
            renderer: "svg",
            loop: true,
        });

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("step") === "thank-you") {
            this.contactNoForm.classList.add("invisible");
            this.switchStep([this.step1s, this.step2s], this.step3s);
            this.animation.play();
        }

        this.back.addEventListener("click", () => {
            this.switchStep([this.step2s, this.step3s], this.step1s);
        });

        this.continue.addEventListener("click", () => {
            if (!this.message.checkValidity()) {
                this.message.classList.add("error");
                this.message.reportValidity();
            } else {
                this.switchStep([this.step1s, this.step3s], this.step2s);
            }
        });

        this.message.addEventListener("input", () => {
            this.message.classList.toggle("error", !this.message.checkValidity());
        });

        this.tags.forEach((tag) => {
            tag.addEventListener("click", () => {
                const isActive = tag.classList.toggle("active");
                const input = <HTMLInputElement>tag.parentElement.querySelector("input");
                input.checked = !isActive;
            });
        });
    }

    private switchStep(deactivates, activate) {
        deactivates.forEach((deactivate) => {
            deactivate.forEach((step) => step.classList.remove("active"));
        });
        activate.forEach((step) => step.classList.add("active"));
    }
}
