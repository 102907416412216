export default class NumberedInfoSwitcher {
    private container: HTMLElement;
    private headings: NodeListOf<HTMLElement>;
    private entries: NodeListOf<HTMLElement>;

    constructor(container) {
        this.container = container;

        this.headings = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".info-page");
        this.entries = <NodeListOf<HTMLElement>>this.container.querySelectorAll(".info-entry");

        this.headings.forEach((entry) => {
            entry.addEventListener("click", (e) => {
                if (!entry.classList.contains("active")) {
                    const indexName = Array.from(entry.classList).find((className) => className.startsWith("index-"));
                    this.setActiveState(indexName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.headings, className);
        this.toggleActiveStage(this.entries, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
