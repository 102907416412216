class TextScramble {
    private el: HTMLElement;
    private chars: string;
    private queue: any[];
    private frame: number;
    private frameRequest: number;
    private resolve: any;

    constructor(el: HTMLElement) {
        this.el = el;
        this.chars = "!<>-_\\/[]{}—=+*^?#________";
        this.update = this.update.bind(this);
    }
    setText(newText) {
        const oldText = this.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || "";
            const to = newText[i] || "";
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
    }
    update() {
        let output = "";
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i];
            if (this.frame >= end) {
                complete++;
                output += to;
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar();
                    this.queue[i].char = char;
                }
                output += `<span class="dud">${char}</span>`;
            } else {
                output += from;
            }
        }
        this.el.innerHTML = output;
        if (complete === this.queue.length) {
            this.resolve();
        } else {
            this.frameRequest = requestAnimationFrame(this.update);
            this.frame++;
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
}

export default class TextScrambler {
    private container: HTMLElement;
    private scramble: HTMLElement;
    private phrases: string[] = [];
    private fx: TextScramble;
    private counter: number = 0;

    constructor(container: HTMLElement) {
        this.container = container;
        this.container.querySelectorAll(".buzzword").forEach((buzzword) => {
            this.phrases.push(buzzword.innerHTML);
        });
        this.scramble = this.container.querySelector(".scramble");
        this.fx = new TextScramble(this.scramble);

        this.next();
    }

    private next = async () => {
        const promise = this.fx.setText(this.phrases[this.counter]);
        this.counter = (this.counter + 1) % this.phrases.length;
        await promise;
        setTimeout(this.next, 800);
    };
}
