export default class PenetrationTest {
    private container: HTMLElement;
    private testTypes: NodeListOf<HTMLElement>;
    private typeTexts: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;
        this.testTypes = this.container.querySelectorAll(".box-type");
        this.typeTexts = this.container.querySelectorAll(".type-text");

        this.testTypes.forEach((entry) => {
            entry.addEventListener("click", (e) => {
                e.stopPropagation();
                if (!entry.classList.contains("active")) {
                    const indexName = Array.from(entry.classList).find((className) => className.startsWith("type-"));
                    this.setActiveState(indexName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.testTypes, className);
        this.toggleActiveStage(this.typeTexts, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
