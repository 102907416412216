export default class Navigation {
    private readonly openClass = "open";
    private readonly button: HTMLButtonElement;
    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        this.container
            .querySelectorAll(".nav-link")
            .forEach((link) => link.addEventListener("click", (e) => this.handleClick()));
        this.button = document.querySelector(".nav-toggle");

        // this is necessary, because clicks apparently aren't always fired on ios
        // see https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html for details
        const eventType = "ontouchstart" in window ? "touchstart" : "click";
        this.button.addEventListener(eventType, (e) => this.toggle(e));
    }

    private toggle(e: Event) {
        const target = e.target as HTMLElement;
        if (target.classList.contains("close")) {
            this.open();
        } else {
            this.close();
        }
    }

    private open() {
        window.scrollTo(0, 0);
        this.container.classList.add(this.openClass);
        this.button.classList.add("open");
        this.button.classList.remove("close");
    }

    private close() {
        this.container.classList.remove(this.openClass);
        this.button.classList.remove("open");
        this.button.classList.add("close");
    }

    private handleClick() {
        this.close();
    }
}
