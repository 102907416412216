export default class BlogScroller {
    private links: NodeListOf<HTMLElement>;
    private container: HTMLElement;
    private current = 0;

    constructor(container: HTMLElement) {
        this.container = container;

        this.links = this.container.querySelectorAll(".blog-link");

        this.initLinks();
    }

    private initLinks() {
        this.links.forEach((link) => {
            const nextButtons = link.querySelectorAll(".next");
            const prevButtons = link.querySelectorAll(".prev");

            nextButtons.forEach((button) => {
                button.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.showNext();
                });
            });

            prevButtons.forEach((button) => {
                button.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.showPrev();
                });
            });
        });
    }

    private showNext() {
        const links = Array.from(this.links);
        const currentLink = links.at(this.current);
        this.current = this.current == links.length - 1 ? 0 : this.current + 1;
        const nextLink = links.at(this.current);
        currentLink.classList.remove("current");
        nextLink.classList.add("current");
    }

    private showPrev() {
        const links = Array.from(this.links);
        const currentLink = links.at(this.current);
        this.current = this.current == 0 ? links.length - 1 : this.current - 1;
        const nextLink = links.at(this.current);
        currentLink.classList.remove("current");
        nextLink.classList.add("current");
    }
}
