import Navigation from "./Navigation";
import ScrollToTop from "./ScrollToTop";
import "./SwiperGalleries";
import PlausibleEvents from "./PlausibleEvents";
import Glitch from "./Glitch";
import FadeIn, { instantFadeIn } from "./FadeIn";
import BlogScroller from "./BlogScroller";
import ContactButton from "./ContactButton";
import CopyToClipboard from "./CopyToClipboard";
import ExpertiseSwitcher from "./ExpertiseSwitcher";
import BuzzwordAnimator from "./BuzzwordAnimator";
import ScrollAnimation from "./ScrollAnimation";
import ContactSection from "./ContactSection";
import InfoSwitcher from "./InfoSwitcher";
import NumberedInfoSwitcher from "./NumberedInfoSwitcher";
import PenetrationTest from "./PenetrationTest";
import Comparison from "./Comparison";
import TextScrambler from "./TextScrambler";
import BoxSwitcher from "./BoxSwitcher";

// navigation
const navContainer = <HTMLElement>document.querySelector(".nav");
const nav = new Navigation(navContainer);

// scroll to top
const scrollToTopButton = <HTMLElement>document.querySelector("#BackToTop");
if (scrollToTopButton) {
    const scrollToTop = new ScrollToTop(scrollToTopButton);
}

const events = new PlausibleEvents();

const glitches = <NodeListOf<HTMLElement>>document.querySelectorAll(".glitch");
glitches.forEach((glitch) => new Glitch(glitch));

const heroVideo = <HTMLVideoElement>document.querySelector(".hero");
if (heroVideo) {
    const fadeIn = new FadeIn(heroVideo);
} else {
    instantFadeIn();
}

const blogLinksContainer = <HTMLElement>document.querySelector(".hero .content");
if (blogLinksContainer) {
    const blogScroller = new BlogScroller(blogLinksContainer);
}

const contactElements = <NodeListOf<HTMLElement>>document.querySelectorAll(".contact-button-container");
contactElements.forEach((contactElement) => {
    new ContactButton(contactElement);
});

const expertiseSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".expertises-section");
expertiseSections.forEach((section) => {
    new ExpertiseSwitcher(section);
});

const infoSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".detail-infos-section");
infoSections.forEach((section) => {
    new InfoSwitcher(section);
});

const buzzwordContainers = <NodeListOf<HTMLElement>>document.querySelectorAll(".talk-about-section");
buzzwordContainers.forEach((container) => {
    new BuzzwordAnimator(container);
    new TextScrambler(container);
});

const scrollSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".scroll-text-section");
scrollSections.forEach((section) => {
    new ScrollAnimation(section);
});

const contactSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".contact-hero");
contactSections.forEach((section) => {
    new ContactSection(section);
});

const numberedInfoSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".numbered-info-section");
numberedInfoSections.forEach((section) => {
    new NumberedInfoSwitcher(section);
});

const penetrationTestSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".penetration-test-section");
penetrationTestSections.forEach((section) => {
    new PenetrationTest(section);
    new BoxSwitcher(section);
});

const comparisonSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".comparison-section");
comparisonSections.forEach((section) => {
    new Comparison(section);
});

// code sections/copy to clipboard
let copyToClipboardInstances = [];
const codeSections = <NodeListOf<HTMLElement>>document.querySelectorAll(".code-section");
codeSections.forEach((cs) => copyToClipboardInstances.push(new CopyToClipboard(cs)));
