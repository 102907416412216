export default class ScrollAnimation {
    private container: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;

        window.addEventListener(
            "scroll",
            () => {
                const containerRect = this.container.getBoundingClientRect();
                const scroll = containerRect.top / window.innerHeight;
                this.container.style.setProperty("--scroll", scroll.toString());
            },
            false
        );
    }
}
