import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

const serviceSwiper = new Swiper(".service-swiper", {
    direction: "horizontal",
    loop: true,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
});

const blogGallerySwiper = new Swiper(".blog-gallery-swiper", {
    direction: "horizontal",
    loop: true,
    spaceBetween: 30,
    slidesPerView: "auto",
    centeredSlides: true,
    pagination: {
        clickable: true,
        el: ".swiper-pagination",
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const comparisonSwiper = new Swiper(".comparison-swiper", {
    direction: "horizontal",
    loop: false,
    centeredSlides: true,
    grabCursor: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        740: {
            slidesPerView: 3,
        },
    },
});
