export default class Comparison {
    private container: HTMLElement;
    private slides: NodeListOf<HTMLElement>;
    private texts: NodeListOf<HTMLElement>;
    private observer;

    constructor(container: HTMLElement) {
        this.container = container;

        this.slides = this.container.querySelectorAll(".swiper-slide");
        this.texts = this.container.querySelectorAll(".comparison-text");

        this.observer = new MutationObserver((mutationList, observer) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    const slide = mutation.target as HTMLElement;
                    if (slide.classList.contains("swiper-slide-active")) {
                        const indexName = Array.from(slide.classList).find((className) =>
                            className.startsWith("index-")
                        );
                        this.setActiveState(indexName);
                    }
                }
            });
        });

        this.slides.forEach((slide) => {
            this.observer.observe(slide, { attributes: true });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.texts, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
