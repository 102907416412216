export default class BoxSwitcher {
    private container: HTMLElement;
    private tests: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;

        this.tests = this.container.querySelectorAll(".test-entry");

        this.tests.forEach((test) => {
            test.addEventListener("click", () => {
                const bigBox = test.querySelector(".big-box");
                if (bigBox.classList.contains("active")) {
                    bigBox.classList.remove("active");
                } else {
                    this.toggleTests(test);
                }
            });
        });
    }

    private toggleTests(test: HTMLElement) {
        this.tests.forEach((t) => {
            const bigBox = t.querySelector(".big-box");
            if (bigBox.classList.contains("active")) {
                bigBox.classList.remove("active");
            }
        });
        test.querySelector(".big-box").classList.add("active");
    }
}
