export default class BuzzwordAnimator {
    private container: HTMLElement;
    private buzzwords: Array<HTMLElement>;
    private animationClass: string = "animate";
    private currentBuzzword: number = 0;

    constructor(container) {
        this.container = container;

        this.buzzwords = Array.from(this.container.querySelectorAll(".buzzword"));

        setInterval(() => {
            this.buzzwords.at(this.currentBuzzword).classList.remove(this.animationClass);
            if (this.currentBuzzword == this.buzzwords.length - 1) {
                this.currentBuzzword = 0;
            } else {
                this.currentBuzzword++;
            }
            this.buzzwords.at(this.currentBuzzword).classList.add(this.animationClass);
        }, 5000);
    }
}
