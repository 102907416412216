export default class FadeIn {
    private container: HTMLElement;
    private video: HTMLVideoElement;
    private elements: NodeListOf<HTMLElement>;
    private delay: number;

    constructor(container: HTMLElement) {
        this.container = container;
        this.video = <HTMLVideoElement>this.container.querySelector(".video");
        this.delay = Number(this.container.dataset.delay);
        this.elements = document.querySelectorAll(".fade-in");

        if (this.delay <= 0) {
            instantFadeIn();
        } else {
            if (this.video) {
                this.video.addEventListener("timeupdate", this.handleTimeUpdateEvent.bind(this));
            } else {
                this.fadeInElements();
            }
        }
    }

    private handleTimeUpdateEvent(e: Event) {
        if (this.video.currentTime > this.delay) {
            this.fadeInElements();
            this.video.removeEventListener("timeupdate", this.handleTimeUpdateEvent);
        }
    }

    private fadeInElements() {
        this.elements.forEach((element) => {
            element.classList.add("on");
        });
    }
}

export function instantFadeIn() {
    const fadeInElements = document.querySelectorAll(".fade-in");
    fadeInElements.forEach((element) => {
        element.classList.add("instant-on");
    });
}
